<template>
	<div>
		<h1>Register</h1>
		<v-stepper v-model="e1">
			<v-stepper-header>
				<v-stepper-step :complete="e1 > 1" step="1">
					User information
				</v-stepper-step>

				<v-divider></v-divider>

				<v-stepper-step :complete="e1 > 2" step="2">
					Organization Information
				</v-stepper-step>
			</v-stepper-header>

			<v-stepper-items>
				<v-stepper-content step="1">
					<v-card class="mb-12" min-height="200px" elevation="0">
						<userRegistration v-model="details.user" @isValid="setIsValid" />
					</v-card>

					<v-btn
						:dark="valid.p1"
						color="dark"
						@click="e1 = 2"
						:disabled="!valid.p1"
					>
						Continue
					</v-btn>
				</v-stepper-content>

				<v-stepper-content step="2">
					<v-card class="mb-12" min-height="200px" elevation="0">
						<organization-registration
							v-if="e1 >= 2"
							v-model="details.organization"
							@isValid="setIsValid2"
						/>
					</v-card>

					<v-btn
						:dark="valid.p2"
						:loading="Submitting"
						color="dark"
						@click="submit"
						:disabled="!valid.p2 || Submitting"
					>
						Submit
					</v-btn>

					<v-btn @click="e1--" text :disabled="Submitting">
						Back
					</v-btn>
				</v-stepper-content>
				<v-stepper-content step="3">
					<v-card class="mb-12" min-height="200px" elevation="0">
						<center>
							<v-icon color="green" size="100">
								mdi-check-circle
							</v-icon>
							<h1>Account created!</h1>
							<br />
							<v-btn dark color="dark" to="login">
								<v-icon>mdi-login</v-icon>
								<span class="pl-2"> Login</span>
							</v-btn>
						</center>
					</v-card>
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
		<alert v-model="alert.show" :Header="alert.hdr" :Message="alert.msg" />
	</div>
</template>

<script>
import userRegistration from "@/components/CreateAccount/userRegistration.vue";
import alert from "@/components/ModalMessages/Alert.vue";
import OrganizationRegistration from "@/components/CreateAccount/organizationRegistration.vue";
import axios from "axios";
export default {
	components: { alert, userRegistration, OrganizationRegistration },
	data() {
		return {
			alert: {
				show: false,
				hdr: "",
				msg: "",
			},
			details: {
				user: {
					FirstName: "test1234",
					LastName: "test1234",
					email: "test@1234.com",
					phone: "1234567890",
					password: "test1234",
				},
				organization: null,
			},
			e1: 1,
			valid: {
				p1: false,
				p2: false,
			},
			Submitting: false,
		};
	},
	methods: {
		setIsValid(v) {
			this.valid.p1 = v;
		},
		setIsValid2(v) {
			this.valid.p2 = v;
		},
		async submit() {
			this.Submitting = true;
			try {
				await axios.post(`${this.$ServerURI}/organization`, this.details);
				this.e1++;
			} catch (error) {
				if (error.response) {
					console.log(error.response);
					console.log(error.response.body);
					this.alert = {
						show: true,
						hdr: "Ruh Roh",
						msg: "Error creating the organization or user.",
					};
				}
			} finally {
				this.Submitting = false;
			}
		},
	},
};
</script>

<style></style>
