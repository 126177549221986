<template>
	<v-container>
		<v-form ref="dform" v-model="isValid">
			<v-row>
				<v-col>
					<v-text-field
						v-model="Title"
						label="Business Name"
						hint="What is the name of your business?"
						:rules="rules.required"
					/>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-text-field
						v-model="Add1"
						label="Address 1"
						hint="What is the street address of your business."
						:rules="rules.required"
					/>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-text-field
						v-model="Add2"
						label="Suite# (optional)"
						hint="What is the second line of your address? If applicable"
					/>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-text-field
						v-model="City"
						label="City"
						hint="What is the city your business is based in?"
						:rules="rules.required"
					/>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-autocomplete
						v-model="State_full"
						label="Please select a state"
						:items="States"
						:rules="rules.required"
						item-text="name"
						hide-no-data
						return-object
					/>
				</v-col>
				<v-col>
					<v-autocomplete
						v-model="Zip"
						label="Please select a Zipcode"
						:items="State_full ? State_full.zips : []"
						:rules="rules.required"
						@input="State = State_full.abbreviation"
						hide-no-data
					/>
				</v-col>
			</v-row>
		</v-form>
	</v-container>
</template>

<script>
export default {
	props: {
		value: {
			default: null,
		},
	},
	data() {
		return {
			Title: "",
			Add1: "",
			Add2: "",
			City: "",
			State: "",
			Zip: "",
			show1: false,
			isValid: false,
			States: [],
			State_full: null,
			rules: {
				required: [(v) => !!v || "Required"],
				min: [(v) => v.length >= 8 || "Min 8 characters"],
				emailRules: [
					(v) => !!v || "E-mail is required",
					(v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
				],
				mustMatch: [
					(value) =>
						value === this.password.substring(0, value.length + 1) ||
						"Passwords must match",
				],
				min10: (value) =>
					(value || "").length >= 14 || "10 digit phone number is required",
			},
		};
	},
	created() {
		this.States = require("@/assets/USStates.json");
	},
	mounted() {
		if (!this.value) return;
		for (const key in this.value) {
			this.$data[key] = this.value[key];
		}
	},
	watch: {
		State_full(v) {
			this.State = v.abbreviation;
		},
		isValid(v) {
			this.$emit("isValid", v);
			if (!v) return;
			this.$emit("input", {
				Title: this.Titl,
				Add1: this.Add1,
				Add2: this.Add2,
				City: this.City,
				State: this.State,
				Zip: this.Zip,
			});
		},
	},
};
</script>

<style></style>
